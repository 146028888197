.btn,
.btn-reset {
  font-family: inherit;
  cursor: pointer;
  display: initial;
  margin: 0;
  padding: 0;
  line-height: initial;

  border: none;
  outline: none;
  background-color: inherit;

  appearance: none;
}

.btn {
  display: inline-block;
  padding: var(--btn-padding);

  color: currentColor;
  font-weight: 600;
  text-decoration: none;

  border: var(--btn-border-width) solid transparent;
  border-radius: var(--btn-border-radius);

  user-select: none;
  touch-callout: none;
}

.btn:hover {
  box-shadow: inset 0 0 0 30em var(--black-05, rgba(0,0,0,.05));
}

.btn:focus {
  outline: none;
  box-shadow: rgba(22, 125, 228, .8) 0 0 3px;
}

.btn:active {
  box-shadow: inset 0 0 0 30em var(--black-20, rgba(0,0,0,.2));
}

.btn:disabled,
.btn.btn-disabled {
  opacity: .5;
  cursor: auto;
}

.btn:disabled:hover,
.btn.btn-disabled:hover {
  box-shadow: none;
}

.btn:disabled:active,
.btn.btn-disabled:active {
  box-shadow: inherit;
}

.btn-outline,
.btn-outline:link,
.btn-outline:visited {
  border: var(--btn-border-width) solid currentColor;
  border-radius: var(--btn-border-radius);
}

.btn-group .btn {
  border-radius: 0;
}

.btn-group .btn:first-child {
  border-top-left-radius: var(--btn-border-radius);
  border-bottom-left-radius: var(--btn-border-radius);
}

.btn-group .btn:last-child {
  border-top-right-radius: var(--btn-border-radius);
  border-bottom-right-radius: var(--btn-border-radius);
}

:root {
  --btn-padding: var(--spacing-extra-small, .25em) var(--spacing-small, .5em);
  --btn-border-radius: var(--spacing-extra-small, .25em);
  --btn-border-width: .125rem;
}
